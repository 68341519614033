import React from 'react'
if (typeof window !== 'undefined') {
  require('./Home.css')
}

const Home = () => (
  <div className="home">
  </div>
)

export default Home
